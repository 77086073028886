<template>
  <div class="analyze-content">

    <section class="top-selection">
      <div class="container">
        <div class="top-categories py-2">
          <div class="top-category">
            <router-link :to="{ name: 'AnalyzeBody'}" class="list-btn" :class="{'active': $route.name === 'AnalyzeBody'}">我的身體</router-link>
          </div>
          <div class="top-category">
            <router-link :to="{ name: 'AnalyzeCloset'}" class="list-btn" :class="{'active': $route.name === 'AnalyzeCloset'}">我的衣櫥</router-link>
          </div>
        </div>
      </div>
    </section>

    <section class="bottom-content">
      <router-view />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex';

export default {
  name: 'Analyze',
  data() {
    return {
    };
  },
  components: {
  },
  beforeDestroy() {
    this.$emit('busy', false);
  },
  computed: {
    ...mapState(['cloths', 'clothCategories']),
  },
  watch: {
    async $route() {
      // await this.refreshCloths();
      const routeName = this.$route.name;
      if (routeName !== 'AnalyzeBody' && routeName !== 'AnalyzeCloset') {
        this.$router.push({
          name: 'AnalyzeCloset'
        });
      }
    }
  },
  async mounted() {
    const routeName = this.$route.name;
    if (routeName !== 'AnalyzeBody' && routeName !== 'AnalyzeCloset') {
      this.$router.push({
        name: 'AnalyzeCloset'
      });
    }
  },
  methods: {
  }
}
</script>

<style scoped>
  .analyze-content {
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .top-selection {
    flex: 0 0 auto;
  }

  .top-categories {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    text-align: center;
  }

  .top-category {
    display: inline-block;
  }

  .bottom-content {
    flex: 1 1;
    overflow-y: scroll;
  }

  .list-btn {
    display: block;
    border-radius: 999px;
    background-color: transparent;
    color: var(--text-dark);
    padding: 2px .5rem;
    font-size: 12px;
    transition: color .2s ease, background-color .2s ease;
    margin-right: .5rem;
  }

  .list-btn:hover {
    text-decoration: none;
  }

  .list-btn.active {
    background-color: var(--text-dark);
    color: #fff;
  }
</style>
  